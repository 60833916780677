<template>
  <div v-show="showCookie" class="pop-cookie flex">
    <div class="text-cookie">
      En poursuivant votre navigation sur notre site, vous acceptez
      l’utilisation de cookies à usages techniques nécessaires à son bon
      fonctionnement, ainsi que des cookies, y compris des cookies tiers, à des
      fins statistiques, de publicité ou de personnalisation pour vous proposer
      des services et des offres adaptés à vos centres d’intérêts sur notre site
      et ceux de tiers. Pour en savoir plus
      <a
        href="https://exval.fr/confidentialite"
        class="text-secondary underline"
        >cliquez ici.
      </a>
    </div>
    <div class="flex btnCheckCookies">
      <button class="button-cookie bg-primary" @click="setCookies">
        <label class="label text-white">Accepter</label>
      </button>
      <button class="button-cookie">
        <label class="label" @click="CookiesNotAccepted">Refuser</label>
      </button>
    </div>
  </div>
  <div :class="showCookie === true ? 'bgCookies' : ''"></div>
</template>

<script>
import { ref } from "vue";
import { useCookies } from "vue3-cookies";

export default {
  name: "Cookies",
  mounted() {
    const cookie_value = this.cookies.get("cookies_consent");

    if (cookie_value === "false") {
      this.showCookie = false;
    } else if (cookie_value === "true") {
      this.CookiesEventWhenAccepted();
    } else if (cookie_value == null) {
      this.showCookie = true;
    }
  },
  setup() {
    const { cookies } = useCookies();
    let showCookie = ref(null);
    return {
      showCookie,
      cookies,
    };
  },
  methods: {
    setCookies() {
      this.cookies.set("cookies_consent", "true");
      this.CookiesEventWhenAccepted();
    },
    CookiesNotAccepted() {
      this.showCookie = false;
      this.cookies.set("cookies_consent", "false");
    },
    CookiesEventWhenAccepted() {
      this.showCookie = false;
      this.$gtag.optIn();
      this.$gtag.pageview(this.$route);
    },
  },
};
</script>

<style scoped>
.pop-cookie {
  height: min-content;
  z-index: 11;
  position: fixed;
  padding: 1% 0;
  margin: 0 8%;
  bottom: 5%;
  border-radius: 10px;
  box-shadow: 8px 8px 16px 4px rgba(133, 139, 146, 0.06);
  border: solid 0.5px #ebebf9;
  background-color: white;
}
/* Mobile and Tablet */
@media (max-width: 979px) {
  .pop-cookie {
    height: 29%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

/* Just mobile */
@media (max-width: 485px) {
  .pop-cookie {
    height: 50%;
  }
}
.text-cookie {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: left;
  color: #363660;
}
/* Mobile and Tablet */
@media (max-width: 979px) {
  .text-cookie {
    padding-left: 0px;
    font-size: 11px;
  }
}
.btnCheckCookies {
  width: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/* Mobile and Tablet */
@media (max-width: 979px) {
  .btnCheckCookies {
    width: 100%;
  }
}
.button-cookie {
  height: 32px;
  width: 42%;
  max-width: 150px;
  border-radius: 4px;
  border: solid 1px #363660;
}
/* Mobile and Tablet */
@media (max-width: 979px) {
  .button-cookie {
    width: 40%;
  }
}
.label {
  height: 20px;
  font-family: Poppins;
  font-size: 0.75em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  cursor: pointer;
}
/* Mobile and Tablet */
@media (max-width: 979px) {
  .label {
    font-size: 11px;
  }
}
.bgCookies {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  opacity: 0.6;
  background-color: #363660;
}
</style> 